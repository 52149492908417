.news {
  width: 100%;
  padding-bottom: 75px;
  border-bottom: 1px solid rgba(186, 143, 150, 0.3);
  margin-top: 40px;
}

.news .info {
  width: calc(90% * 0.95);
  max-width: 1640px;
  margin: 0 auto;
}

.newsDescription {
  margin-bottom: 32px;
  text-align: center;
}

.newsDescription p {
  font-size: 18px;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Новая карточка новостей */
.newsCard {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  border: none;
}

.newsCard:hover {
  box-shadow: 0 16px 32px rgba(65, 92, 237, 0.15);
}

.imageContainer {
  position: relative;
  height: 220px;
  overflow: hidden;
}

.newsImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.newsCard:hover .newsImage {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #415CED, #6A8CFF);
  z-index: 1;
}

.cardContent {
  padding: 24px;
  position: relative;
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.dateContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #666;
  font-size: 14px;
}

.calendarIcon {
  margin-right: 8px;
  color: #415CED;
}

.newsTitle {
  font-size: 20px;
  font-weight: 600;
  color: #2C3E50;
  margin-bottom: 12px;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.newsDescription {
  color: #666;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

.readMore {
  color: #415CED;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
}

.readMoreIcon {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.newsCard:hover .readMoreIcon {
  transform: translateX(4px);
}

/* Кнопка "Смотреть все" */
.viewAllContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.viewAllButton {
  background: #415CED;
  border: none;
  font-size: 16px;
  height: 48px;
  padding: 0 32px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(65, 92, 237, 0.2);
  transition: all 0.3s ease;
}

.viewAllButton:hover {
  background: #3348C5;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(65, 92, 237, 0.3);
}

.loadingCard {
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .newsCard {
    height: auto;
  }
  
  .imageContainer {
    height: 180px;
  }
  
  .cardContent {
    padding: 20px;
  }
  
  .newsTitle {
    font-size: 18px;
  }
  
  .newsDescription {
    font-size: 14px;
  }
  
  .viewAllButton {
    width: 100%;
  }
}