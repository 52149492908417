.partners {
  width: 100%;
  background: #415ced;
}

.partners .info {
  width: calc(90% * 0.95);
  max-width: 1640px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  row-gap: 30px;
}

.partners .info .block {
  min-height: 90px;
  padding: 10px;
  border-radius: 20px;
  background-color: white;
  width: 10%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: auto;
}
.partners .info .block a {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.partners .info .block img {
  width: auto;
  max-height: 90px;
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  .partners .info .block {
    width: 21%;
  }
}
@media screen and (max-width: 768px) {
  .partners .info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .partners .info .block {
    width: 43%;
  }
  .partners .info .block img {
    width: auto;
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .partners .info {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .partners .info .block {
    width: 100%;
  }
}

.partnersContainer {
  width: 100%;
  padding: 60px 0;
  overflow: hidden;
}

/* Шапка с количеством партнеров */
.partnersHeader {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto 60px;
  padding: 40px;
  background: #415ced;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  box-shadow: 0 15px 30px rgba(65, 92, 237, 0.2);
}

.partnersCount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.countNumber {
  font-size: 120px;
  font-weight: 700;
  line-height: 1;
}

.countText {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 2px;
}

.partnersDescription {
  font-size: 20px;
  max-width: 60%;
  line-height: 1.5;
}

/* Заголовок секции */
.partnersTitle {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto 40px;
  text-align: center;
}

.partnersTitle h2 {
  font-size: 48px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

.partnersTitle p {
  font-size: 18px;
  color: #666;
}

/* Бегущая строка */
.partnersMarquee {
  width: 100%;
  overflow: hidden;
  background: #f8f9fa;
  padding: 30px 0;
  margin-bottom: 40px;
}

.marqueeTrack {
  display: flex;
  animation: marquee 30s linear infinite;
  width: max-content;
}

.marqueeItem {
  padding: 0 30px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.marqueeItem.active {
  transform: scale(1.2);
}

.marqueeItem img {
  height: 60px;
  max-width: 120px;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.marqueeItem:hover img,
.marqueeItem.active img {
  filter: grayscale(0%);
  opacity: 1;
}

/* Кнопка "Показать всех партнеров" */
.viewAllContainer {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto 40px;
  display: flex;
  justify-content: center;
}

.viewAllButton {
  background: #415ced;
  color: white;
  font-weight: 500;
  border: none;
  font-size: 16px;
  height: 48px;
  padding: 0 32px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(65, 92, 237, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.viewAllButton:hover {
  background: #3348c5;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(65, 92, 237, 0.3);
}

/* Контейнер для сетки партнеров */
.partnersGridContainer {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto 60px;
  overflow: hidden;
}

/* Сетка партнеров */
.partnersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
}

.partnerCard {
  background: white;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: center;
}

.partnerCard.active {
  box-shadow: 0 10px 30px rgba(65, 92, 237, 0.2);
  transform: translateY(-5px);
}

.partnerCard a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.partnerImageContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.partnerImageContainer img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.partnerName {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
  line-height: 1.4;
  height: 40px;
  overflow: hidden;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Адаптивность */
@media screen and (max-width: 1200px) {
  .partnersHeader {
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }

  .partnersCount {
    align-items: center;
    margin-bottom: 20px;
  }

  .countNumber {
    font-size: 80px;
  }

  .partnersDescription {
    max-width: 100%;
    font-size: 18px;
  }

  .partnersTitle h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .partnersGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
  }

  .partnerCard {
    padding: 16px;
  }

  .partnerImageContainer {
    height: 80px;
  }

  .countNumber {
    font-size: 60px;
  }

  .countText {
    font-size: 18px;
  }

  .partnersTitle h2 {
    font-size: 28px;
  }

  .partnersTitle p {
    font-size: 16px;
  }

  .marqueeItem img {
    height: 50px;
  }
}

@media screen and (max-width: 500px) {
  .partnersGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .partnersHeader {
    padding: 20px;
  }

  .countNumber {
    font-size: 50px;
  }

  .partnersDescription {
    font-size: 16px;
  }

  .viewAllButton {
    width: 90%;
  }
}
