.slide {
    display: flex;
    justify-content: space-between;
    font-family: 'Jost', sans-serif;
    padding-left: 20px;
    border: 1px solid black;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    height: 350px; /* Убедитесь, что height задан для .slide */
}

.text {
    padding-top: 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    padding-right: 20px;
    padding-bottom: 20px;
}

.text h2 {
    height: calc(1.5em * 3);
    font-size: 32px;
    color: #4D4D4D;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.date {
    font-size: 18px;
    color: #888;
    margin-bottom: 10px;
}
.image {
    width: 50%; /* 1/3 ширины родительского контейнера */
    height: 100%; /* Полная высота родителя */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden; /* Скрывает выходящие части изображения */
    flex-shrink: 0; /* Запрещает сжатие */
}

.image img {
    width: 100%; /* Растягивает изображение по ширине контейнера */
    height: 100%; /* Растягивает изображение по высоте контейнера */
    object-fit: cover; /* Обрезает изображение, чтобы оно заполнило контейнер */
    object-position: center; /* Центрирует изображение */
    background-size: contain;
    border-radius: 20px;
    box-sizing: border-box;
    display: block; /* Убирает возможные пробелы вокруг изображения */
}

.arrow {
    font-size: 32px;
    background-color: rgba(65, 92, 237, 1);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    z-index: 1;
}
.arrow1 {
    font-size: 24px;
    background-color: rgba(65, 92, 237, 1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    z-index: 1;
}

.prev {
    left: -25px;
}

.next {
    right: -25px;
}
.prev1 {
    left: 5px;
}

.next1 {
    right: 5px;
}


@media (max-width: 1200px) {
    .slide {
        flex-direction: column-reverse;
        text-align: center;
        padding-left: 0;
        padding-right: 0;

        .text {
            padding-right: 15px;
            padding-left: 15px;
            margin-bottom: 20px;
        }

        .image {
            text-align: center;
            margin-right: 0;
        }

        .text h2 {
            text-align: left;
        }
    }
}