.news {
  width: 100%;
  padding-bottom: 75px;
  border-bottom: 1px solid rgba(186, 143, 150, 0.3);
  margin-top: 40px;
}
.news .info {
  width: calc(90% * 0.95);
  max-width: 1640px;
  margin: 0 auto;
}

.text {
  font-family: "Jost", sans-serif;
}

.text h3 {
  font-weight: 500;
  font-size: 22px;
  margin: 0;
  padding: 0;
  height: calc(1.3em * 3);
  color: #4d4d4d;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.text {
  color: #4d4d4d;
}
.text p {
  margin-top: 0;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
}
.text div {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
}

.card {
  background-color: white;
  height: 100%;
  border: 1px solid black;
}

.event {
  padding-top: 10px;
  font-family: "Jost", sans-serif;
  display: flex;
  justify-content: space-between;
}

.newsDescription {
  margin-bottom: 32px;
  text-align: center;
}

.newsDescription p {
  font-size: 18px;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Новая карточка событий */
.eventCard {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.eventCard:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.imageContainer {
  position: relative;
  overflow: hidden;
  height: 220px;
}

.eventImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.eventCard:hover .eventImage {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(65, 92, 237, 0.8),
    rgba(120, 65, 237, 0.8)
  );
  z-index: 1;
}

.dateTag {
  position: absolute;
  bottom: 16px;
  left: 16px;
  background: rgba(255, 255, 255, 0.9);
  color: #415ced;
  padding: 8px 16px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  z-index: 2;
}

.cardContent {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.eventTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1.4;
  color: #333;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничение в 3 строки */
  -webkit-box-orient: vertical;
  line-clamp: 2; /* Стандартное свойство */
  overflow: hidden;

  word-break: break-word; /* Предотвращает разрыв слова на середине */
  overflow-wrap: break-word; /* Перенос длинных слов */
}

.eventDetails {
  margin-bottom: 20px;
  flex-grow: 1;
}

.eventLocation {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
}

.locationIcon {
  margin-right: 8px;
  color: #415ced;
}

.readMore {
  display: flex;
  align-items: center;
  color: #415ced;
  font-weight: 500;
  margin-top: auto;
}

.readMoreIcon {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.eventCard:hover .readMoreIcon {
  transform: translateX(5px);
}

/* Кнопка "Смотреть все" */
.viewAllContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.viewAllButton {
  background: #415ced;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.viewAllButton:hover {
  background: #3349c5;
}

.buttonIcon {
  margin-left: 10px;
}

/* Адаптивность */
@media screen and (max-width: 1200px) {
  .newsGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 992px) {
  .newsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .newsGrid {
    grid-template-columns: 1fr;
  }

  .eventTitle {
    font-size: 18px;
  }

  .viewAllButton {
    width: 100%;
  }
}

.dateAnonce {
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: rgba(65, 92, 237, 1);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  z-index: 2;
  width: 70%;
  border-radius: 5px;
}
.textNews {
  overflow: hidden; /* Обрезать содержимое, выходящее за пределы блока */
  display: -webkit-box; /* Использовать Flexbox для обрезки по количеству строк */
  -webkit-box-orient: vertical; /* Установить вертикальную ориентацию */
  -webkit-line-clamp: 4; /* Количество видимых строк */
  text-overflow: ellipsis; /* Добавить троеточие в конце обрезанного текста */
  line-height: 1.3; /* Межстрочный интервал */
  padding: 10px; /* Внутренний отступ */
  box-sizing: border-box;
}

/* Сетка анонсов - 3 блока в линию */
.newsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Сброс стилей для NavLink */
.navLinkReset {
  text-decoration: none;
  color: inherit;
}

.navLinkReset:hover,
.navLinkReset:focus,
.navLinkReset:active {
  text-decoration: none;
  color: inherit;
}
