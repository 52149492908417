.flagsBlock {
  width: 90%;
  max-width: 1640px;
  margin: 0 auto;
  padding: 40px 0;
}


.flagsContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 колонок по 1 фракции */
  grid-template-rows: repeat(2, 1fr); /* 2 строки */
  gap: 24px;
  width: 100%; /* Обеспечивает, что контейнер займет всю доступную ширину */
  grid-auto-rows: minmax(100px, auto); /* Устанавливаем минимальную высоту строки */
}

.flagItem {
  background: white;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.flagItem:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
}

.flagItem.active {
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
}

.flagImage {
  width: 120px;
  height: 80px;
  margin: 0 auto 16px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flagImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.flagItem h3 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 8px;
  font-weight: 600;
}

.countryInfo {
  margin-top: 16px;
  overflow: hidden;
}

.countryInfo p {
  font-size: 14px;
  color: #4d4d4d;
  line-height: 1.5;
  margin-bottom: 16px;
}

.learnMoreBtn {
  background: #415ced;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.learnMoreBtn:hover {
  background: #3348c5;
}

@media (max-width: 768px) {
  .flagsContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
  }

  .flagItem {
    padding: 16px;
  }

  .flagImage {
    width: 90px;
    height: 60px;
    margin-bottom: 12px;
  }

  .flagItem h3 {
    font-size: 16px;
  }

  .countryInfo p {
    font-size: 13px;
  }

  .learnMoreBtn {
    padding: 6px 12px;
    font-size: 13px;
  }
}
