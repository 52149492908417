/* Основные стили */
.main {
  overflow-x: hidden;
}

/* Контейнер для информационных блоков */
.infoBlocksContainer {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s ease-out;
}

.infoBlocksContainer.animate {
  opacity: 1;
  transform: translateY(0);
}

.infoBlocks {
  display: flex;
  gap: 32px;
  margin: 60px auto;
  width: 90%;
  max-width: 1640px;
}

.infoBlock {
  flex: 1;
  padding: 40px 32px;
  background: #fff;
  border-radius: 16px;
  text-align: center;
  transition: all 0.4s ease;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.infoBlock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #415ced, #6a8cff);
  z-index: 2;
}

.infoBlock::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(65, 92, 237, 0.05) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  z-index: -1;
}

.infoBlock:hover {
  transform: translateY(-8px);
  box-shadow: 0 16px 32px rgba(65, 92, 237, 0.15);
}

.infoBlockIcon {
  width: 64px;
  height: 64px;
  margin: 0 auto 24px;
  background: #415ced;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  box-shadow: 0 8px 16px rgba(65, 92, 237, 0.2);
}

.infoBlock h3 {
  font-size: 28px;
  margin-bottom: 16px;
  color: #2c3e50;
  font-weight: 600;
}

.infoBlock p {
  color: #4d4d4d;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 1.6;
}

.infoBlock button {
  background-color: #415ced !important;
  color: white !important;
  border: none !important;
  height: 48px;
  padding: 0 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(65, 92, 237, 0.2);
}

.infoBlock button:hover {
  background-color: #3348c5 !important;
  box-shadow: 0 6px 16px rgba(65, 92, 237, 0.3);
  transform: translateY(-2px);
}

/* Общий контейнер для секций с анимацией */
.sectionContainer {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s ease-out;
  margin: 80px 0;
}

.sectionContainer.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Заголовки для блоков */
.flagsBlockTitle,
.partnersBlockTitle {
  text-align: center;
  margin-bottom: 40px;
}

.flagsBlockTitle h2,
.partnersBlockTitle h2 {
  font-size: 36px;
  color: #2c3e50;
  margin-bottom: 16px;
  font-weight: 600;
}

.flagsBlockTitle p,
.partnersBlockTitle p {
  font-size: 18px;
  color: #4d4d4d;
  max-width: 600px;
  margin: 0 auto;
}

/* Стили для модального окна */
:global(.ant-modal-content) {
  padding: 32px !important;
  border-radius: 16px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15) !important;
}

:global(.ant-modal-close) {
  top: 24px !important;
  right: 24px !important;
}

:global(.ant-modal-close-x) {
  font-size: 20px !important;
  color: #4d4d4d !important;
}

:global(.ant-modal-header) {
  margin-bottom: 24px !important;
}

:global(.ant-modal-title) {
  font-size: 24px !important;
  color: #2c3e50 !important;
}

/* Анимация появления */
:global(.ant-modal) {
  animation: modalFadeIn 0.3s ease-out !important;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Адаптивность */
@media (max-width: 768px) {
  .infoBlocks {
    flex-direction: column;
    gap: 24px;
    margin: 40px auto;
  }

  .infoBlock {
    padding: 32px 24px;
  }

  .infoBlock h3 {
    font-size: 24px;
  }

  .infoBlockIcon {
    width: 56px;
    height: 56px;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .flagsBlockTitle h2,
  .partnersBlockTitle h2 {
    font-size: 28px;
  }

  .flagsBlockTitle p,
  .partnersBlockTitle p {
    font-size: 16px;
  }

  .sectionContainer {
    margin: 60px 0;
  }
}
