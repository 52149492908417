.root {
  width: 90%;
  margin: 0 auto;
  max-width: 1640px;
  padding: 40px 0;
}

.articleDetailBlock {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.date {
  color: #415ced;
  font-size: 16px;
  font-weight: 500;
  padding: 32px 32px 0;
}

.title {
  padding: 0 32px;
}

.title h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #2c3e50;
  margin: 16px 0 32px;
  font-weight: 600;
}

.articleDetailBlock img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0;
}

.text {
  padding: 32px;
  font-size: 18px;
  line-height: 1.7;
  color: #4d4d4d;
}

/* Стилизация параграфов внутри текста */
.text p {
  margin-bottom: 24px;
}

/* Блок "Также рекомендуем" */
.bottom {
  margin-top: 60px;
}

.also {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.also h2 {
  font-size: 32px;
  color: #2c3e50;
  font-weight: 600;
  margin: 0;
}

.also a {
  background-color: #415ced;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.also a:hover {
  background-color: #3348c5;
  transform: translateY(-2px);
}

.arrow {
  display: flex;
  align-items: center;
}

/* Анимации при скролле */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.articleDetailBlock {
  animation: fadeInUp 0.6s ease-out;
}

/* Адаптивность */
@media (max-width: 768px) {
  .root {
    width: 95%;
    padding: 20px 0;
  }

  .title h1 {
    font-size: 28px;
  }

  .date,
  .text {
    padding: 20px;
  }

  .also {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  .also h2 {
    font-size: 24px;
  }
}

.eventDetails {
    padding: 0 32px;
    margin-bottom: 32px;
}

.eventInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    color: #4D4D4D;
    font-size: 15px;
}

.infoItem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.infoIcon {
    color: #415CED;
    font-size: 16px;
}

.separator {
    color: #CBD5E0;
    font-size: 16px;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .eventInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    
    .separator {
        display: none;
    }
    
    .infoItem {
        width: 100%;
    }
}
