.youtube {
    width: 100%;
    padding-bottom: 40px;
    padding-top: 30px;
    border-bottom: 1px solid rgba(186, 143, 150, 1);
    background-color: rgba(65, 92, 237, 1);
    margin-top: 50px;
}

.youtubeGallery {
    border-bottom: 1px solid black;
    background-color: white;
    margin-top: 50px;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}
.ytp-cued-thumbnail-overlay-image{
    background-position: left;
}
.infoBlock {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 600px;
    scrollbar-color: white;

}

.infoBlock::-webkit-scrollbar {
    height: 5px;
    width: 250px;
}

.infoBlock::-webkit-scrollbar-track {
    background: rgba(65, 92, 237, 1);
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #aeafaf;
    border-radius: 6px;
}

.youtubeVideo {
    border-radius: 20px;
    transition: transform 0.3s ease;
}
.youtubeVideo:hover {
    transform: scale(1.1);
}
.youtube .info {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    column-gap: 30px;
}

.presentation {
    display: flex;
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
}

.presentation a {
    text-decoration-line: none;
}

.presentationNew {
    display: flex;
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
}

.presentationNew a {
    text-decoration-line: none;
}

.youtube .info h2 {
    white-space: normal;
    text-wrap: normal;
    font-size: 40px;
    margin: 0;
    color: white;
    margin-bottom: 20px;
    font-weight: 400;
}

.youtubeGallery .info h2 {
    white-space: normal;
    text-wrap: normal;
    font-size: 40px;
    margin: 0;
    color: #4D4D4D;
    margin-bottom: 20px;
    font-weight: 400;
}

.oneInfo {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: white;
    padding-bottom: 20px;
}

.time {
    min-width: 100px;
}

.text {
    min-width: 270px;
}

.new h2 {
    font-size: 60px;
    font-weight: 700;
    padding-bottom: 32px;
    max-width: 390px;
    margin: 0;
}
.video{
    width: 100%;
    height: 470px;
}
.video iframe{
    margin: 0px;
    padding: 0px;
    height: 100%;
    border: none;
}
.new .desc {
    font-size: 16px;
    color: white;
    padding-bottom: 80px;
    max-width: 390px
}

.youtubeGallery .new .desc {
    font-size: 16px;
    color: #4D4D4D;
    padding-bottom: 80px;
    max-width: 390px
}


@media screen and (max-width: 1530px) {
    .infoBlock {
        width: 500px;
    }
}
@media screen and (max-width: 1430px) {
    .infoBlock {
        width: 400px;
    }
}
@media screen and (max-width: 1430px) {
    .infoBlock {
        width: 300px;
    }
}
@media screen and (max-width: 1200px) {
    .info {
        flex-direction: column;
    }

    .presentation {
        justify-content: center;
        margin-top: 20px;
    }

    .new h2 {
        padding-bottom: 20px;
    }

    .presentationNew {
        padding-bottom: 0;
        margin-bottom: 15px;
        justify-content: center;
    }

    .new .desc {
        font-size: 16px;
        color: white;
        padding-bottom: 20px;
        max-width: 390px
    }

}

@media screen and  (max-width: 900px) {
    .video {
        padding-top: 20px;
        width: 100%;
        height: max-content;
        height: 300px;
    }
    .infoBlock {
        width: 400px;
    }
}
@media screen and  (max-width: 500px) {
    .infoBlock {
        width: 300px;
    }
}
