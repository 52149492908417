.forum {
    width: calc(90% * 0.95);
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 50px;
}

.forum h1 {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    margin: 0 auto;
}

a.name {
    color: black;
    text-decoration: none;
}

a.name:hover {
    color: #000;
    text-decoration-line: underline;
}
a.description {
    color: black;
    text-decoration: none;
}

a.description:hover {
    color: #000;
    text-decoration-line: underline;
}

.author {
    border: 1px solid #4D4D4D;
    border-radius: 16px;
    font-size: 18px;
}

.author img {
    max-width: 100%;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
}

.author .info {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: space-between;
    padding: 10px 20px 20px;
}
.author .info .name{
    text-align: center;
}
.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5;
    height: 4.5em;
    margin-bottom: 10px;
}
